import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 2px #eee solid;
  padding-top: 25px;
`;

export const Spacer = styled.div`
  @media (min-width: 769px) {
    flex-grow: 2;
  }
`;

export const MobileHeader = styled.div`
  padding: 0 20px;

  .brand {
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 30px;
  }
`;

export const NavLink = styled.a`
  display: block;
  width: 100%;
  padding: 15px 30px;

  &:not([href]) {
    color: var(--devo-text-color);
  }

  &.subtitle {
    font-size: 14px;
    color: #757575;
  }

  &.active {
    svg {
      color: var(--devo-red);
    }

    color: var(--devo-red);
    font-weight: 600;
  }

  &:hover {
    background-color: #FAFAFA;
    color: var(--primary-label-color) !important;
    cursor: pointer;

    &.active {
      color: var(--devo-red) !important;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;